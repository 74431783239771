
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { formatLocalTime } from '@/utils/format-time';
import { RefundType, RefundTypeEnum, ResponseError, getOrder } from '@/services/api';
import { PartialOrder } from '@/interfaces';
import { ElMessage } from 'element-plus';

export default defineComponent({
  setup(props) {
    const route = useRoute();
    const order = ref<PartialOrder>({});
    const orderId = route.params.id as string;

    const formatRefundType = {
      [RefundTypeEnum.FULL]: '全額退款',
      [RefundTypeEnum.PARTIAL]: '按未使用天數'
    };

    const fetchOrder = async() => {
      try {
        const { data } = await getOrder({ orderId });
        order.value = data;
      } catch (error) {
        ElMessage.error((error as ResponseError).response?.data.message);
      }
    };

    onMounted(() => {
      fetchOrder();
    });

    return {
      order,
      formatRefundType,
      formatLocalTime
    };
  }
});
