import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_main = _resolveComponent("el-main")!

  return (_openBlock(), _createBlock(_component_el_main, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_descriptions, {
        title: "收據詳情",
        column: 1,
        border: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_descriptions_item, {
            width: 10,
            label: "會員編號"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.order?.user?.memberNo || '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, {
            width: 10,
            label: "訂單編號"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.order?.orderNumber || '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, {
            width: 10,
            label: "關聯訂單編號"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.order?.refundOrderNumber || '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, {
            width: 10,
            label: "金流訂單編號"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.order?.transactionId || '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, {
            width: 10,
            label: "金流平台"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.order?.paymentGateway || '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, {
            width: 10,
            label: "訂閱方案"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.order?.plan?.name ? _ctx.order?.paymentGateway : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, {
            width: 10,
            label: "交易金額"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.order?.amount || '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, {
            width: 10,
            label: "付款方式"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(`orderPaymentMethod.${_ctx.order?.paymentMethod}`) ? _ctx.$t(`orderPaymentMethod.${_ctx.order?.paymentMethod}`) : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, {
            width: 10,
            label: "付款時間"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.order?.paidAt ? _ctx.formatLocalTime(_ctx.order.paidAt) : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, {
            width: 10,
            label: "退款時間"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.order?.refundedAt ? _ctx.formatLocalTime(_ctx.order.refundedAt) : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, {
            width: 10,
            label: "退款比例"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.order?.refundType ? _ctx.formatRefundType[_ctx.order.refundType] : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, {
            width: 10,
            label: "退款原因"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.order?.refundReason || '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, {
            width: 10,
            label: "訂閱時間"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.order?.subscription?.createdAt ? _ctx.formatLocalTime(_ctx.order?.subscription?.createdAt) : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, {
            width: 10,
            label: "訂閱結束"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.order?.subscription?.endedAt ? _ctx.formatLocalTime(_ctx.order?.subscription?.endedAt) : '-'), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}